import React, { useState } from 'react';
import FetchFilesContent from './fetchFilesContent';
import RemoveFilesComponent from './deleteFiles';

function FetchFiles() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataType, setDataType] = useState(''); // 'failed', 'completed', etc.
  const [labId, setLabId] = useState(''); // Will dynamically set lab_id
  const [activeButton, setActiveButton] = useState(''); // Track the active button

  const labs = {
    MGH_LAB: '5fd0f8b70c8b4b71e275a2b7',
    DLW_LAB: '5e8e9b8f684a216cbd7d7920',
    DLW_COLONY: '660d60ab7a7759f399253c81',
  };

  const handleLabSelection = (e) => {
    setLabId(e.target.value);
  };

  const handleButtonClick = (id) => {
    setActiveButton(id); // Set the active button based on the clicked button
  };

  const handleFilesRemoved = () => {
    setData(null); // Clear the data when files are removed
  };

  const fetchData = async (type) => {
    if (!labId) {
      alert('Please select a lab');
      return;
    }

    setLoading(true);
    setDataType(type);
    try {
      const response = await fetch(`https://scan-files.labsquire.com/v2.0/${type}/${labId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <label htmlFor="labSelector">Select Lab:</label>
      <select id="labSelector" onChange={handleLabSelection} value={labId}>
        <option value="">-- Select a Lab --</option>
        <option value={labs.MGH_LAB}>MGH Lab</option>
        <option value={labs.DLW_LAB}>DLW Lab</option>
        <option value={labs.DLW_COLONY}>DLW Colony</option>
      </select>

      <RemoveFilesComponent
        labId={labId} // Passing labId as a prop
        activeButton={activeButton}
        onButtonClick={handleButtonClick}
        onFilesRemoved={handleFilesRemoved}
      />

      <FetchFilesContent
        data={data}
        loading={loading}
        dataType={dataType}
        fetchData={fetchData}
      />


    </div>
  );
}

export default FetchFiles;
