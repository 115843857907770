import './App.css';

import React from 'react';
import FetchFiles from './components/fileDetails'

function App() {
  return (
    <div>
      <h1 align="center">Scan FTP Files</h1>
      <FetchFiles />
    </div>
  );
}

export default App;
