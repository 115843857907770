import React, { useState } from 'react';
import '../css/fetchFiles.css';

function RemoveFilesComponent({ labId, activeButton, onButtonClick, onFilesRemoved }) {
    console.log("labId", labId)
    const [loading, setLoading] = useState(false);

    const Remove = async () => {
        if (!labId) {
            alert('Please select a lab first');
            return;
        }

        // Show confirmation dialog
        const userConfirmed = window.confirm('Are you sure you want to delete all files?');

        if (!userConfirmed) {
            // If the user clicks "Cancel", exit the function
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://scan-files.labsquire.com/v2.0/remove/${labId}`, {
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log('Files deleted:', result.message);
            setLoading(false);

            // Notify parent component that files have been removed
            if (onFilesRemoved) {
                onFilesRemoved();
            }
        } catch (error) {
            console.error('Error removing files:', error.message);
            setLoading(false);
        }
    };

    return (
        <div style={{ textAlign: 'right' }}>
            <button
                onClick={() => {
                    Remove();
                    onButtonClick(labId); // Notify parent that this button was clicked
                }}
                disabled={loading}
                className={`button1 ${activeButton === labId ? 'button-active1' : ''}`}
            >
                {loading ? 'Removing...' : 'Remove All Files'}
            </button>
        </div>
    );
}

export default RemoveFilesComponent;
