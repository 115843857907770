import React from 'react';
import '../css/fetchFiles.css';

const FetchFilesContent = ({ data, loading, dataType, fetchData }) => (
    <div className="container">
        <div className="button-container">
            <button
                onClick={() => fetchData('uploads')}
                className={`button ${dataType === 'uploads' ? 'button-active' : ''}`}
            >
                Uploads 📁
            </button>
            <button
                onClick={() => fetchData('processing')}
                className={`button ${dataType === 'processing' ? 'button-active' : ''}`}
            >
                Progress 📁
            </button>
            <button
                onClick={() => fetchData('attachments')}
                className={`button ${dataType === 'attachments' ? 'button-active' : ''}`}
            >
                Attachments 📁
            </button>
            <button
                onClick={() => fetchData('completed')}
                className={`button ${dataType === 'completed' ? 'button-active' : ''}`}
            >
                Completed 📁
            </button>
            <button
                onClick={() => fetchData('failed')}
                className={`button ${dataType === 'failed' ? 'button-active' : ''}`}
            >
                Failed 📁
            </button>
        </div>
        {loading ? (
            <p className="loading">Loading...</p>
        ) : data ? (
            <table className="table-container">
                <thead>
                    <tr>
                        <th>Filename</th>
                        <th>Filesize</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((file, index) => (
                        <tr key={index}>
                            <td>{file.fileName}</td>
                            <td>{file.fileSize}</td>
                            <td>{file.lastModifiedTime}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>Click a folder icon to load data.</p>
        )}
    </div>
);

export default FetchFilesContent;
